// Various functions to help with job offers presentation
// code is split between this file and utils.ts, that was
// created first

import { Job } from 'generated/apolloComponents'
import useTranslation from 'next-translate/useTranslation'
import IconData from '@/components/molecules/IconData'
import escapeStringRegexp from 'escape-string-regexp'

export const shouldDisplayDefaultJobCover = (job: Job): boolean => {
  if (!job.company) return false
  if (
    job.company.jobCover?.url &&
    !job.company.jobCover.url.includes('missing')
  )
    return true

  return false
}

export const shouldDisplayCustomJobCover = (job: Job): boolean => {
  if (!job.company) return false
  if (job.topBanner?.url && !job.topBanner?.url.includes('missing')) return true

  return false
}

export const workModes = (job: Job): IconData[] => {
  const { t } = useTranslation()
  const data: IconData[] = []
  job.workModes?.forEach((mode) => {
    if (mode === 'hybrid') {
      data.push({
        data: t('jobs:work_mode_hybrid'),
        subData:
          Number(job.environment?.remotePossible) > 0
            ? `${job.environment?.remotePossible}% ${t('jobs:time_remote')}`
            : undefined,
      })
    } else if (mode === 'full-remote') {
      data.push({
        data: t('jobs:work_mode_full-remote'),
        subData:
          Number(job.workFrom?.length) > 0
            ? job.workFrom?.join(', ')
            : undefined,
      })
    } else {
      data.push({
        data: t(`jobs:work_mode_${mode}`),
      })
    }
  })
  return data
}

export const filterWithSearchTerms = (
  option: { label: string; value: string; data: any },
  input: string
) => {
  if (input) {
    return input.split(' ').every((term) => {
      const regex = new RegExp(escapeStringRegexp(term), 'i')
      return regex.test(option.data.searchTerms)
    })
  }

  return true
}

export const filterFromStart = (
  option: { label: string; value: string; data: any },
  input: string
) => {
  if (input) {
    const regex = new RegExp(
      `^${escapeStringRegexp(input)}|\\s${escapeStringRegexp(input)}`,
      'i'
    )
    return regex.test(option.label)
  }
  return true
}
