/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useAnalyticsPush } from '@/context/analyticsContext'
import { getConsentsFromCookie } from '@/helpers/cookieUtils'
import { Ads } from '@/data/ads'
import Modal from './Modal'

const Banner = () => {
  const { consentsReady } = useAnalyticsPush()
  const [display, setDisplay] = useState(false)
  const [width, setWidth] = useState(0)
  const [creativeId] = useState(Math.floor(Math.random() * 3))
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (consentsReady) {
      const cookieData = getConsentsFromCookie()

      if ((cookieData as boolean[])[1]) {
        setDisplay(true)
      }
    }
  }, [consentsReady])

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const creativeDesktop = Ads.desktop[creativeId]
  const creativeMobile = Ads.mobile[creativeId]

  const swapTimestamp = (input: string) =>
    input.replace('[timestamp]', `${Date.now()}`)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  return display ? (
    <section className='mx-auto relative overflow-hidden w-full my-5'>
      <div className='md:container'>
        <div className='flex flex-wrap relative'>
          <a
            href={creativeDesktop?.url}
            target='_blank'
            className='hidden md:block'
          >
            <picture className='hidden md:block aspect-[1800/150] w-full'>
              <img
                src={creativeDesktop.src}
                width={1800}
                height={150}
                alt='Explore opportunities at Goldman Sachs'
                className='w-full aspect-[1800/150]'
              />
            </picture>
          </a>

          <a
            href={creativeMobile?.url}
            target='_blank'
            className='block md:hidden'
          >
            <picture className='block md:hidden aspect-[768/192] w-full'>
              <img
                src={creativeMobile.src}
                width={768}
                height={192}
                alt='Explore opportunities at Goldman Sachs'
                className='w-full aspect-[768/192]'
              />
            </picture>
          </a>
          {width > 768 ? (
            <img
              src={swapTimestamp(creativeDesktop?.impression as string)}
              className='absolute opacity-0'
              height='1'
              width='1'
              alt='Advertisement'
            />
          ) : (
            <img
              src={swapTimestamp(creativeMobile?.impression as string)}
              className='absolute opacity-0'
              height='1'
              width='1'
              alt='Advertisement'
            />
          )}
          <div className='absolute top-0 right-0 p-2'>
            <span
              className='bg-gray-200 text-gray-700 text-xxs px-1 py-1 rounded cursor-pointer'
              onClick={openModal}
            >
              Ad
            </span>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} closeModal={closeModal}>
        <h2 className='text-lg font-bold mb-4'>{t('common:ad_title')}</h2>
        <p className='mt-2'>
          <b>{t('common:ad_advertiser')}</b> Goldman Sachs - Corporate Brand
        </p>
        <p className='mt-2'>
          <b>{t('common:ad_why')}</b> {t('common:ad_why_explanation')}
        </p>
        <p className='mt-2'>{t('common:ad_contact')}</p>
      </Modal>
    </section>
  ) : (
    <section className='mx-auto relative overflow-hidden w-full py-5 mb-5'>
      <div className='md:container'>
        <div className='flex flex-wrap'>
          <div className='w-full aspect-[4/1] md:aspect-[1800/150] bg-neutral-300'>
            &nbsp;
          </div>
        </div>
      </div>
    </section>
  )
}
export default Banner
