import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { Fragment } from 'react'
import { ReactNode } from 'react-transition-group/node_modules/@types/react'

interface IProps {
  isOpen: boolean
  closeModal: () => void
  children: ReactNode
  className?: string
  naked?: boolean
}

const Modal = ({
  isOpen,
  closeModal,
  children,
  className = '',
  naked = false,
}: IProps) => (
  <Transition appear show={isOpen} as={Fragment}>
    <Dialog
      as='div'
      className='fixed inset-0 z-50 overflow-y-auto'
      onClose={closeModal}
    >
      <Transition.Child
        as='div'
        className='bg-black bg-opacity-40'
        enter='ease-out duration-300'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='ease-in duration-200'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <div className='min-h-screen px-4 text-center flex items-center justify-center'>
          <Dialog.Overlay className='fixed inset-0' />
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div
              className={`${className} inline-block w-full max-w-4xl p-14 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl overflow-y-scroll`}
              style={{ maxHeight: '95vh' }}
            >
              <div className={`${naked ? '' : 'mt-2'}`}>{children}</div>
              <div className={`${naked ? '' : 'mt-2'}`}>
                {!naked && (
                  <button
                    type='button'
                    className='absolute top-2 right-4'
                    onClick={closeModal}
                  >
                    <XMarkIcon width={20} />
                  </button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Transition.Child>
    </Dialog>
  </Transition>
)

export default Modal
function useModal(): { closeModal: any } {
  throw new Error('Function not implemented.')
}
