/* eslint-disable no-template-curly-in-string */
/* eslint-disable import/prefer-default-export */
export const Ads = {
  slug: 'goldman-sachs',
  advertiser: 'Goldman Sachs - Corporate Brand',
  desktop: [
    {
      src: 'https://bulldogjob.pl/next-images/local-ads/ENG_Conversion_Building-Infrastructures_Warsaw_English_BULLDOGJOB_1800x150_STATIC.jpg',
      impression:
        'https://ad.doubleclick.net/ddm/trackimp/N450204.4676903BULLDOGJOB.COM/B33223038.417234406;dc_trk_aid=609876113;dc_trk_cid=232158185;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1?',
      url: 'https://ad.doubleclick.net/ddm/trackclk/N450204.4676903BULLDOGJOB.COM/B33223038.417234406;dc_trk_aid=609876113;dc_trk_cid=232158185;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1',
    },
    {
      src: 'https://bulldogjob.pl/next-images/local-ads/ENG_Conversion_Protecting-Data_Warsaw_English_BULLDOGJOB_1800x150_STATIC.jpg',
      impression:
        'https://ad.doubleclick.net/ddm/trackimp/N450204.4676903BULLDOGJOB.COM/B33223038.417432854;dc_trk_aid=609880646;dc_trk_cid=232198168;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1?',
      url: 'https://ad.doubleclick.net/ddm/trackclk/N450204.4676903BULLDOGJOB.COM/B33223038.417432854;dc_trk_aid=609880646;dc_trk_cid=232198168;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1',
    },
    {
      src: 'https://bulldogjob.pl/next-images/local-ads/ENG_Conversion_Constructing-Models_Warsaw_English_BULLDOGJOB_1800x150_STATIC.jpg',
      impression:
        'https://ad.doubleclick.net/ddm/trackimp/N450204.4676903BULLDOGJOB.COM/B33223038.417750345;dc_trk_aid=609920686;dc_trk_cid=232162040;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1?',
      url: 'https://ad.doubleclick.net/ddm/trackclk/N450204.4676903BULLDOGJOB.COM/B33223038.417750345;dc_trk_aid=609920686;dc_trk_cid=232162040;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1',
    },
    // {
    //   src: 'https://bulldogjob.pl/next-images/local-ads/ENG_Conversion_12000-Engineers_Warsaw_English_BULLDOGJOB_1800x150_STATIC.jpg',
    //   impression:
    //     'https://ad.doubleclick.net/ddm/trackimp/N450204.4676903BULLDOGJOB.COM/B33223038.417750354;dc_trk_aid=609920689;dc_trk_cid=232162043;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1?',
    //   url: 'https://ad.doubleclick.net/ddm/trackclk/N450204.4676903BULLDOGJOB.COM/B33223038.417750354;dc_trk_aid=609920689;dc_trk_cid=232162043;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1',
    // },
  ],
  mobile: [
    {
      src: 'https://bulldogjob.pl/next-images/local-ads/ENG_Conversion_Explore-Engineering-3_Warsaw_English_BULLDOGJOB_768x192_STATIC.jpg',
      impression:
        'https://ad.doubleclick.net/ddm/trackimp/N450204.4676903BULLDOGJOB.COM/B33223038.417432845;dc_trk_aid=609920695;dc_trk_cid=232198171;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1?',
      url: 'https://ad.doubleclick.net/ddm/trackclk/N450204.4676903BULLDOGJOB.COM/B33223038.417432845;dc_trk_aid=609920695;dc_trk_cid=232198171;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1',
    },
    {
      src: 'https://bulldogjob.pl/next-images/local-ads/ENG_Conversion_Explore-Engineering-1_Warsaw_English_BULLDOGJOB_768x192_STATIC.jpg',
      impression:
        'https://ad.doubleclick.net/ddm/trackimp/N450204.4676903BULLDOGJOB.COM/B33223038.417434897;dc_trk_aid=609920692;dc_trk_cid=232158188;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1?',
      url: 'https://ad.doubleclick.net/ddm/trackclk/N450204.4676903BULLDOGJOB.COM/B33223038.417434897;dc_trk_aid=609920692;dc_trk_cid=232158188;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1',
    },
    {
      src: 'https://bulldogjob.pl/next-images/local-ads/ENG_Conversion_Explore-Engineering-2_Warsaw_English_BULLDOGJOB_768x192_STATIC.jpg',
      impression:
        'https://ad.doubleclick.net/ddm/trackimp/N450204.4676903BULLDOGJOB.COM/B33223038.417750447;dc_trk_aid=609880649;dc_trk_cid=232160261;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1?',
      url: 'https://ad.doubleclick.net/ddm/trackclk/N450204.4676903BULLDOGJOB.COM/B33223038.417750447;dc_trk_aid=609880649;dc_trk_cid=232160261;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1',
    },
    // {
    //   src: 'https://bulldogjob.pl/next-images/local-ads/ENG_Conversion_12000-Engineers_Warsaw_English_BULLDOGJOB_768x192_STATIC.jpg',
    //   impression:
    //     'https://ad.doubleclick.net/ddm/trackimp/N450204.4676903BULLDOGJOB.COM/B33223038.417750450;dc_trk_aid=609880655;dc_trk_cid=232162046;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1?',
    //   url: 'https://ad.doubleclick.net/ddm/trackclk/N450204.4676903BULLDOGJOB.COM/B33223038.417750450;dc_trk_aid=609880655;dc_trk_cid=232162046;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1',
    // },
  ],
}
